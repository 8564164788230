import React from "react";
import { ContentArea, Link } from "app/shared";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FisherEditor } from "app/fishery";

interface Params {
  id: string;
}

const FisherPage: React.FC<RouteComponentProps<Params>> = ({ match }) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <ContentArea>
      <Breadcrumb mb={4} fontWeight="medium" fontSize="lg">
        <BreadcrumbItem>
          <BreadcrumbLink href="/fishers" as={Link}>
            {t("pages.breadcrumbs.fishers")}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href={`/fishers/${match.params.id}`} as={Link}>
            {t("pages.breadcrumbs.fisher")} {match.params.id}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <FisherEditor
        id={match.params.id}
        onCreate={(fisherId: string) => history.push(`/fishers/${fisherId}`)}
        onDelete={() => history.push("/fishers")}
      />
    </ContentArea>
  );
};

export default FisherPage;
