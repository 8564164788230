import * as Yup from "yup";
import i18next from "i18next";

/**
 * Tag types
 */
export const TagTypeCreateSchema = Yup.object().shape({
  id: Yup.number()
    .positive()
    .integer()
    .min(1, i18next.t("schemas.tooShort"))
    .max(50, i18next.t("schemas.tooLong"))
    .required(i18next.t("schemas.required")),
  name: Yup.string()
    .min(3, i18next.t("schemas.tooShort"))
    .max(255, i18next.t("schemas.tooLong"))
    .required(i18next.t("schemas.required")),
  urlMaxSize: Yup.number()
    .positive()
    .integer()
    .min(4, i18next.t("schemas.tooShort"))
    .max(1000, i18next.t("schemas.tooLong"))
    .required(i18next.t("schemas.required")),
  uidSize: Yup.number()
    .positive()
    .integer()
    .min(4, i18next.t("schemas.tooShort"))
    .max(1000, i18next.t("schemas.tooLong"))
    .required(i18next.t("schemas.required")),
});

export const TagTypeUpdateSchema = Yup.object().shape({
  status: Yup.string().oneOf(["active"]),
  name: Yup.string()
    .min(3, i18next.t("schemas.tooShort"))
    .max(255, i18next.t("schemas.tooLong")),
  urlMaxSize: Yup.number()
    .positive()
    .integer()
    .min(4, i18next.t("schemas.tooShort"))
    .max(1000, i18next.t("schemas.tooLong")),
  uidSize: Yup.number()
    .positive()
    .integer()
    .min(4, i18next.t("schemas.tooShort"))
    .max(1000, i18next.t("schemas.tooLong")),
});
