import {
  Button,
  ButtonGroup,
  Alert,
  AlertIcon,
  Box,
  Spinner,
} from "@chakra-ui/react";
import { FormField } from "app/shared";
import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useStoreContext } from "store";
import {
  FishSpeciesCreateSchema,
  FishSpeciesUpdateSchema,
} from "../models/schemas";
import {
  fishSpeciesToCmd,
  FishSpeciesSaveCommand,
  FishSpeciesUpdateCommand,
} from "../models/commands";
import { useTranslation } from "react-i18next";
import { Prompt } from "react-router-dom";

interface Props {
  id: string;
  onCreate: (fishSpeciesId: string) => void;
  onDelete: () => void;
}

const FishSpeciesEditor: React.FC<Props> = ({ id, onCreate, onDelete }) => {
  const { t } = useTranslation();
  const { fishesSpeciesStore: fishSpeciesStore } = useStoreContext();
  const mode = id === "create" ? "create" : "update";

  useEffect(() => {
    if (mode === "update") {
      fishSpeciesStore.getFish(id);
    }
  }, [fishSpeciesStore, mode, id]);

  // Prevent initiating form without fish values
  if (mode === "update" && fishSpeciesStore.fishSpecies?.id !== id) {
    return <Spinner />;
  }

  return (
    <Box>
      {fishSpeciesStore.state === "pending" && <Spinner mb={4} />}
      {fishSpeciesStore.error?.length > 0 && (
        <Alert status="error" mb={4}>
          <AlertIcon />
          {fishSpeciesStore.error}
        </Alert>
      )}
      <Formik
        initialValues={
          mode === "update" && fishSpeciesStore.fishSpecies
            ? fishSpeciesToCmd(fishSpeciesStore.fishSpecies)
            : {}
        }
        validationSchema={
          mode === "create" ? FishSpeciesCreateSchema : FishSpeciesUpdateSchema
        }
        onSubmit={async (values, actions) => {
          const promise =
            mode === "create"
              ? fishSpeciesStore.saveFishSpecies(
                  values as FishSpeciesSaveCommand
                )
              : fishSpeciesStore.updateFishSpecies(
                  id,
                  values as FishSpeciesUpdateCommand
                );
          const fishSpecies = await promise;
          actions.resetForm({ values });

          if (mode === "create") {
            onCreate(fishSpecies.id);
          }
        }}>
        {({ isSubmitting, submitForm, setFieldValue, dirty }) => (
          <Form>
            {/* <FormDebug /> */}
            <Prompt
              when={dirty}
              message={t("fishery.fishSpeciesEditor.pendingChanges")}
            />
            <ButtonGroup
              spacing="6"
              isDisabled={isSubmitting || fishSpeciesStore.state === "pending"}
              mb={4}>
              <Button
                onClick={() => {
                  setFieldValue("status", "active");
                  submitForm();
                }}>
                {t("fishery.fishSpeciesEditor.save")}
              </Button>
              {mode === "update" && (
                <>
                  <Button
                    colorScheme="red"
                    onClick={async () => {
                      if (
                        window.confirm(t("fishery.fishSpeciesEditor.confirm"))
                      ) {
                        await fishSpeciesStore.deleteFishSpecies(id);
                        onDelete();
                      }
                    }}>
                    {t("fishery.fishSpeciesEditor.delete")}
                  </Button>
                </>
              )}
            </ButtonGroup>
            <FormField
              name="species"
              type="string"
              label={t("fishery.fishSpeciesEditor.species")}
            />
            <FormField
              name="scientificName"
              type="string"
              label={t("fishery.fishSpeciesEditor.scientificName")}
            />
            <FormField
              name="commonName"
              type="string"
              label={t("fishery.fishSpeciesEditor.commonName")}
            />
            <FormField
              name="description"
              type="string"
              label={t("fishery.fishSpeciesEditor.description")}
            />
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default observer(FishSpeciesEditor);
