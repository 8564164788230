import { Flex, Box } from "@chakra-ui/react";
import React from "react";
import { BurgerMenu } from "./burgerMenu";
import { Link, Image } from "app/shared";
import LogoImage from "./static/logo.png";

export const Navbar: React.FC = () => {
  return (
    <Box width="100%">
      <Flex
        height="100%"
        maxWidth="maxWidth"
        mx="auto"
        px={2}
        alignItems="center"
        justifyContent="space-between">
        <Box />
        <Link href="/" width="200px">
          <Image src={LogoImage} maxHeight="70px" />
        </Link>
        <BurgerMenu />
      </Flex>
    </Box>
  );
};
