import { Grid, GridProps, PositionProps } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { theme } from "styles";

const NavbarArea = styled(Grid)<GridProps & PositionProps>`
  height: ${theme.sizes.navbarHeight.sm};
  ${theme.mediaQueries.lg} {
    height: ${theme.sizes.navbarHeight.lg};
  }
`;

NavbarArea.defaultProps = {
  gridArea: "navbar",
  borderBottom: "1px",
  borderColor: "black",
};

export default NavbarArea;
