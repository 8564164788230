import {
  Button,
  ButtonGroup,
  Alert,
  AlertIcon,
  Box,
  Spinner,
} from "@chakra-ui/react";
import { FormField, Image, FileInput } from "app/shared";
import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useStoreContext } from "store";
import { FisherCreateSchema, FisherUpdateSchema } from "../models/schemas";
import {
  FisherSaveCommand,
  fisherToCmd,
  FisherUpdateCommand,
} from "../models/commands";
import { useTranslation } from "react-i18next";
import { Prompt } from "react-router-dom";

interface Props {
  id: string;
  onCreate: (fisherId: string) => void;
  onDelete: () => void;
}

const FisherEditor: React.FC<Props> = ({ id, onCreate, onDelete }) => {
  const { t } = useTranslation();
  const { fisherStore } = useStoreContext();
  const [fisherPhoto, setFisherPhoto] = useState<File | undefined>(undefined);
  const mode = id === "create" ? "create" : "update";

  useEffect(() => {
    if (mode === "update") {
      fisherStore.getFisher(id);
    }
  }, [fisherStore, mode, id]);

  // Prevent initiating form without fisher initial values
  if (mode === "update" && fisherStore.fisher?.id !== id) {
    return <Spinner />;
  }

  return (
    <Box>
      {fisherStore.state === "pending" && <Spinner mb={4} />}
      {fisherStore.error?.length > 0 && (
        <Alert status="error" mb={4}>
          <AlertIcon />
          {fisherStore.error}
        </Alert>
      )}
      <Formik
        initialValues={
          mode === "update" && fisherStore.fisher
            ? fisherToCmd(fisherStore.fisher)
            : {}
        }
        validationSchema={
          mode === "create" ? FisherCreateSchema : FisherUpdateSchema
        }
        onSubmit={async (values, actions) => {
          const promise =
            mode === "create"
              ? fisherStore.saveFisher(values as FisherSaveCommand)
              : fisherStore.updateFisher(
                  id,
                  values as FisherUpdateCommand,
                  fisherPhoto
                );
          const fisher = await promise;
          actions.resetForm({ values });
          setFisherPhoto(undefined);

          if (mode === "create") {
            onCreate(fisher.id);
          }
        }}>
        {({ isSubmitting, submitForm, setFieldValue, dirty }) => (
          <Form>
            {/* <FormDebug /> */}
            <Prompt
              when={dirty}
              message={t("fishery.fisherEditor.pendingChanges")}
            />
            <ButtonGroup
              spacing="6"
              isDisabled={isSubmitting || fisherStore.state === "pending"}
              mb={4}>
              <Button
                onClick={() => {
                  setFieldValue("status", "active");
                  submitForm();
                }}>
                {t("fishery.fisherEditor.save")}
              </Button>
              {mode === "update" && (
                <>
                  <Button
                    colorScheme="red"
                    onClick={async () => {
                      if (window.confirm(t("fishery.fisherEditor.confirm"))) {
                        await fisherStore.deleteFisher(id);
                        onDelete();
                      }
                    }}>
                    {t("fishery.fisherEditor.delete")}
                  </Button>
                </>
              )}
            </ButtonGroup>
            {mode === "update" && (
              <Box maxWidth="150px">
                {(fisherPhoto || fisherStore.fisher?.photo) && (
                  <Image
                    src={
                      fisherPhoto
                        ? URL.createObjectURL(fisherPhoto)
                        : fisherStore.fisher?.photo
                    }
                    mb={4}
                  />
                )}
                <FileInput
                  name="photo"
                  label={t("fishery.fisherEditor.changePhoto")}
                  onFilesChange={(name, files) => {
                    if (files.length) {
                      setFisherPhoto(files[0]);
                      setFieldValue(name, "photo.jpg");
                    }
                  }}
                  mb={3}
                />
              </Box>
            )}
            <FormField
              name="foodBusinessId"
              type="string"
              label={t("fishery.fisherEditor.foodBusinessId")}
            />
            <FormField
              name="name"
              type="string"
              label={t("fishery.fisherEditor.name")}
            />
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default observer(FisherEditor);
