import { ChakraProvider } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { MainLayout, NavbarArea } from "../app/shared";
import { Navbar } from "app/navbar";
import { theme } from "../styles";
import { AmplifyAuthenticator, AmplifySignIn } from "@aws-amplify/ui-react";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import { StoreProvider } from "store";
import NotFoundPage from "./NotFoundPage";
import TagTypesPage from "./TagTypesPage";
import TagTypePage from "./TagTypePage";
import FishersPage from "./FishersPage";
import FisherPage from "./FisherPage";
import FishesSpeciesPage from "./FishesSpeciesPage";
import FishSpeciesPage from "./FishSpeciesPage";
import TagsPage from "./TagsPage";
import TradeUnitsPage from "./TradeUnitsPage";
import TradeUnitPage from "./TradeUnitPage";
import TagCreatePage from "./TagCreatePage";

/**
 * Creates app with authenticator and disables sign ups
 */
const App = () => {
  const { t } = useTranslation();
  const [authState, setAuthState] = useState<AuthState>();

  useEffect(() => {
    return onAuthUIStateChange((nextAuthState) => {
      setAuthState(nextAuthState);
    });
  });

  return (
    <ChakraProvider theme={theme} resetCSS={true}>
      {authState === AuthState.SignedIn ? (
        <StoreProvider>
          <BrowserRouter>
            <MainLayout>
              <NavbarArea>
                <Navbar />
              </NavbarArea>
              <Switch>
                <Route exact path="/" component={TradeUnitsPage} />
                <Route exact path="/tags" component={TagsPage} />
                <Route exact path="/tags/create" component={TagCreatePage} />
                <Route exact path="/types" component={TagTypesPage} />
                <Route path="/types/:id" component={TagTypePage} />
                <Route exact path="/fishers" component={FishersPage} />
                <Route path="/fishers/:id" component={FisherPage} />
                <Route
                  exact
                  path="/fish-species"
                  component={FishesSpeciesPage}
                />
                <Route path="/fish-species/:id" component={FishSpeciesPage} />
                <Route exact path="/trade-units" component={TradeUnitsPage} />
                <Route path="/trade-units/:id" component={TradeUnitPage} />
                <Route path="/404" component={NotFoundPage} />
                <Redirect from="*" to="/404" />
              </Switch>
            </MainLayout>
          </BrowserRouter>
        </StoreProvider>
      ) : (
        <AmplifyAuthenticator>
          <AmplifySignIn
            slot="sign-in"
            hideSignUp={true}
            headerText={t("pages.app.signInHeader")}
          />
        </AmplifyAuthenticator>
      )}
    </ChakraProvider>
  );
};

export default App;
