import React from "react";
import { ContentArea, Link } from "app/shared";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { TagCreateForm } from "app/tags";

const TagCreatePage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ContentArea>
      <Breadcrumb mb={4} fontWeight="medium" fontSize="lg">
        <BreadcrumbItem>
          <BreadcrumbLink href="/tags" as={Link}>
            {t("pages.breadcrumbs.tags")}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink href="/create" as={Link}>
            {t("pages.breadcrumbs.tagCreate")}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <TagCreateForm />
    </ContentArea>
  );
};

export default TagCreatePage;
