import {
  Alert,
  AlertIcon,
  IconButton,
  ButtonGroup,
  Spinner,
  Box,
} from "@chakra-ui/react";
import { ClassNames } from "@emotion/react";
import { tableStyle } from "app/shared/tableStyle";
import { observer } from "mobx-react-lite";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { useStoreContext } from "store";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { Link, ListFilters, ListPaginator } from "app/shared";
import { useTranslation } from "react-i18next";
import { FishSpecies } from "../models";
import { FilterField } from "network/models";

const FishesSpeciesList: React.FC = () => {
  const { t } = useTranslation();
  const { fishesSpeciesStore: fishSpeciesStore } = useStoreContext();
  const [filters, setFilters] = useState<FilterField[]>([
    { field: "status", operator: "eq", values: ["active"] },
  ]);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const offset = pageNumber * pageSize;
  const totalRecords = fishSpeciesStore.lastPage
    ? offset + 1
    : offset + pageSize + 1;

  useEffect(() => {
    fishSpeciesStore.getFishSpecies(pageNumber, pageSize, filters);
  }, [fishSpeciesStore, pageNumber, pageSize, filters]);

  const rowClassName = (fishSpecies: FishSpecies) => {
    return fishSpecies.status === "active"
      ? { "column-datagrid-odd": true }
      : { "column-datagrid": true };
  };

  return (
    <Box>
      {fishSpeciesStore.state === "pending" && <Spinner mb={4} />}
      {fishSpeciesStore.error?.length > 0 && (
        <Alert status="error" mb={4}>
          <AlertIcon />
          {fishSpeciesStore.error}
        </Alert>
      )}
      <ListFilters
        fields={[
          "id",
          "createdAt",
          "status",
          "species",
          "scientificName",
          "commonName",
          "description",
        ]}
        filters={filters}
        onFilterChange={(filters) => setFilters(filters)}
      />
      <ClassNames>
        {({ css }) => (
          <DataTable
            rowClassName={rowClassName}
            lazy={true}
            paginator={true}
            paginatorTemplate="PrevPageLink NextPageLink"
            totalRecords={totalRecords}
            rows={pageSize}
            value={fishSpeciesStore.fishesSpecies}
            first={offset}
            emptyMessage={t("fishery.fishesSpeciesList.noResults")}
            loading={fishSpeciesStore.state === "pending"}
            className={css`
              ${tableStyle}
            `}
            paginatorLeft={
              <ListPaginator
                defaultValue={pageSize}
                onPageSizeChange={(pageSize) => {
                  setPageSize(pageSize);
                  setPageNumber(0);
                }}
              />
            }
            paginatorRight={<Box />}
            onPage={(e: any) => setPageNumber(e.page)}>
            <Column field="id" header={t("fishery.fishesSpeciesList.id")} />
            <Column
              field="createdAt"
              header={t("fishery.fishesSpeciesList.createdAt")}
            />
            <Column
              field="status"
              header={t("fishery.fishesSpeciesList.status")}
            />
            <Column
              field="commonName"
              header={t("fishery.fishesSpeciesList.commonName")}
            />
            <Column
              header={t("fishery.fishesSpeciesList.actions")}
              body={(fishSpecies: FishSpecies) => (
                <ButtonGroup size="sm" spacing="2">
                  <Link href={`/fish-species/${fishSpecies.id}`}>
                    <IconButton
                      aria-label="Edit"
                      colorScheme="black"
                      icon={<EditIcon />}
                    />
                  </Link>
                  <IconButton
                    aria-label="Delete"
                    colorScheme="red"
                    icon={<DeleteIcon />}
                    onClick={async () => {
                      if (
                        window.confirm(t("fishery.fishesSpeciesList.confirm"))
                      ) {
                        await fishSpeciesStore.deleteFishSpecies(
                          fishSpecies.id
                        );
                        setPageNumber(0);
                      }
                    }}
                  />
                </ButtonGroup>
              )}
            />
          </DataTable>
        )}
      </ClassNames>
    </Box>
  );
};

export default observer(FishesSpeciesList);
