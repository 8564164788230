import React, { useContext } from "react";
import { TagTypeStore } from "app/tags/stores";
import {
  FisherStore,
  FishesSpeciesStore,
  TradeUnitStore,
} from "app/fishery/stores";
import TagStore from "app/tags/stores/TagStore";
import CertifierStore from "app/users/stores";

interface StoreContextProps {
  tagTypeStore: TagTypeStore;
  fisherStore: FisherStore;
  fishesSpeciesStore: FishesSpeciesStore;
  tagStore: TagStore;
  tradeUnitStore: TradeUnitStore;
  certifierStore: CertifierStore;
}

export const StoreContext = React.createContext<StoreContextProps>(
  {} as StoreContextProps
);

export const useStoreContext = () => useContext(StoreContext);
