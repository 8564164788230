import { makeAutoObservable, runInAction } from "mobx";
import { FilterField } from "network/models";
import { TagType } from "../models";
import { TagTypeSaveCommand, TagTypeUpdateCommand } from "../models/commands";
import {
  getTagType,
  getTagTypes,
  saveTagType,
  updateTagType,
  deleteTagType,
} from "../services";

export default class TagTypeStore {
  tagTypes: TagType[] = [];
  lastPage = true;
  tagType?: TagType;
  state: "pending" | "done" | "error" = "done";
  error = "";
  paginationTokens: Record<number, string | undefined> = {};

  constructor() {
    makeAutoObservable(this);
  }

  async wrapRequest<T>(body: () => Promise<T>): Promise<T> {
    try {
      this.state = "pending";
      this.error = "";
      const res = await body();
      runInAction(() => {
        this.state = "done";
      });
      return res;
    } catch (err) {
      runInAction(() => {
        this.state = "error";
        this.error = err.message;
      });
      throw err;
    }
  }

  async getTagTypes(
    page: number,
    size: number,
    filter?: FilterField[]
  ): Promise<TagType[]> {
    return this.wrapRequest(async () => {
      const { meta, data } = await getTagTypes({
        limit: size,
        filter,
        paginationToken: this.paginationTokens[page - 1],
      });
      runInAction(() => {
        this.tagTypes = data;
        this.lastPage = meta.paginationToken ? false : true;
        this.paginationTokens[page] = meta.paginationToken;
      });
      return data;
    });
  }

  async getTagType(tagTypeId: string): Promise<TagType> {
    // Get from cache
    const cachedTagType = this.tagTypes.find((it) => it.id === tagTypeId);
    if (cachedTagType) {
      this.tagType = cachedTagType;
      return cachedTagType;
    }

    return this.wrapRequest(async () => {
      const tagType = await getTagType(tagTypeId);
      runInAction(() => {
        this.tagType = tagType;
      });
      return tagType;
    });
  }

  async saveTagType(cmd: TagTypeSaveCommand): Promise<TagType> {
    return this.wrapRequest(async () => {
      const tagType = await saveTagType(cmd);
      runInAction(() => {
        this.tagType = tagType;
        this.tagTypes.push(tagType);
      });
      return tagType;
    });
  }

  async updateTagType(
    tagTypeId: string,
    cmd: TagTypeUpdateCommand
  ): Promise<TagType> {
    return this.wrapRequest(async () => {
      const tagType = await updateTagType(tagTypeId, cmd);
      runInAction(() => {
        this.tagType = tagType;
        this.tagTypes = this.tagTypes.map((it) =>
          it.id === tagType.id ? { ...tagType } : it
        );
      });
      return tagType;
    });
  }

  async deleteTagType(tagTypeId: string): Promise<void> {
    return this.wrapRequest(async () => {
      await deleteTagType(tagTypeId);
      runInAction(() => {
        this.tagType = undefined;
        this.tagTypes = this.tagTypes.filter((it) => it.id !== tagTypeId);
      });
    });
  }
}
