import React, { useState } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  chakra,
  IconButton,
  Stack,
} from "@chakra-ui/react";
import { FilterField, filterfieldOperatorOptions } from "network/models";
import { Select } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FormField, FormFieldArray } from "app/shared";
import { Form, Formik } from "formik";
import { FiltersSchema } from "./models/schemas";
import { DeleteIcon } from "@chakra-ui/icons";
import { ChildrenProps } from "../forms/FormFieldArray";

interface Props {
  fields: string[];
  filters: FilterField[];
  onFilterChange(filters: FilterField[]): void;
  disabledFields?: string[];
}

export const ListFilters: React.FC<Props> = ({
  fields,
  filters,
  onFilterChange,
  disabledFields,
}) => {
  const { t } = useTranslation();
  const [accordionIndex, setAccordionIndex] = useState(-1);

  return (
    <Formik
      initialValues={{ filters }}
      validationSchema={FiltersSchema}
      onSubmit={async (values) => {
        setAccordionIndex(-1);
        await onFilterChange(values.filters);
      }}>
      {({ isSubmitting }) => (
        <Form>
          {/* <FormDebug /> */}
          <Accordion
            allowToggle
            index={accordionIndex}
            onChange={(index: any) => setAccordionIndex(index)}>
            <FormFieldArray
              name="filters"
              label={t("shared.listFilters.filters")}
              defaultArrayValue={{
                field: "field",
                operator: "eq",
                values: ["value"],
              }}
              onNewArrayItem={(_, values) => setAccordionIndex(values.length)}>
              {(values) =>
                values
                  .filter(({ value }) => !disabledFields?.includes(value.field))
                  .map(
                    ({
                      index,
                      value,
                      getFieldName,
                      remove,
                    }: ChildrenProps<FilterField>) => (
                      <AccordionItem key={index} mb={3}>
                        <AccordionButton>
                          <Stack width="100%" isInline align="center">
                            <Box flexGrow={1} textAlign="left">
                              {value.field}{" "}
                              <chakra.span fontWeight="bold">
                                {filterfieldOperatorOptions.find(
                                  (it) => it.value === value.operator
                                )?.name ?? value.operator}
                              </chakra.span>{" "}
                              {value.values.join(", ")}
                            </Box>
                            <IconButton
                              as={Box}
                              aria-label="Delete"
                              colorScheme="red"
                              size="xs"
                              icon={<DeleteIcon />}
                              onClick={() =>
                                window.confirm(
                                  t("shared.listFilters.confirm")
                                ) && remove()
                              }
                            />
                            <AccordionIcon />
                          </Stack>
                        </AccordionButton>
                        <AccordionPanel mt={4}>
                          <Stack isInline>
                            <FormField
                              name={getFieldName("field")}
                              type="file"
                              label={t("shared.listFilters.field")}>
                              {(props) => (
                                <Select
                                  placeholder={t(
                                    "shared.listFilters.selectOption"
                                  )}
                                  {...props}>
                                  {fields
                                    .filter(
                                      (field) =>
                                        !disabledFields?.includes(field)
                                    )
                                    .map((field) => (
                                      <option key={field} value={field}>
                                        {field}
                                      </option>
                                    ))}
                                </Select>
                              )}
                            </FormField>
                            <FormField
                              name={getFieldName("operator")}
                              type="file"
                              label={t("shared.listFilters.operator")}>
                              {(props) => (
                                <Select
                                  placeholder={t(
                                    "shared.listFilters.selectOption"
                                  )}
                                  {...props}>
                                  {filterfieldOperatorOptions.map((option) => (
                                    <option
                                      key={option.value}
                                      value={option.value}>
                                      {option.name}
                                    </option>
                                  ))}
                                </Select>
                              )}
                            </FormField>
                          </Stack>
                          <FormFieldArray
                            name={getFieldName("values")}
                            label={t("shared.listFilters.values")}
                            defaultArrayValue="value">
                            {(values) =>
                              values.map(({ index, getFieldName, remove }) => (
                                <Stack key={index} isInline align="flex-end">
                                  <FormField
                                    name={getFieldName()}
                                    type="string"
                                    label={t("shared.listFilters.value")}
                                  />
                                  <IconButton
                                    aria-label="Delete"
                                    colorScheme="red"
                                    icon={<DeleteIcon />}
                                    onClick={() =>
                                      window.confirm(
                                        t("shared.listFilters.confirm")
                                      ) && remove()
                                    }
                                  />
                                </Stack>
                              ))
                            }
                          </FormFieldArray>
                        </AccordionPanel>
                      </AccordionItem>
                    )
                  )
              }
            </FormFieldArray>
          </Accordion>
          <Button mb={3} isLoading={isSubmitting} type="submit" size="sm">
            {t("shared.listFilters.filter")}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
