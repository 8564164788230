import { makeAutoObservable, runInAction } from "mobx";
import { FilterField } from "network/models";
import { Fisher } from "../models";
import { FisherSaveCommand, FisherUpdateCommand } from "../models/commands";
import {
  getFisher,
  getFishers,
  saveFisher,
  updateFisher,
  deleteFisher,
  putS3Files,
} from "../services";

export default class FisherStore {
  fishers: Fisher[] = [];
  lastPage = true;
  fisher?: Fisher;
  state: "pending" | "done" | "error" = "done";
  error = "";
  paginationTokens: Record<number, string | undefined> = {};

  constructor() {
    makeAutoObservable(this);
  }

  async wrapRequest<T>(body: () => Promise<T>): Promise<T> {
    try {
      this.state = "pending";
      this.error = "";
      const res = await body();
      runInAction(() => {
        this.state = "done";
      });
      return res;
    } catch (err) {
      runInAction(() => {
        this.state = "error";
        this.error = err.message;
      });
      throw err;
    }
  }

  async getFishers(
    page: number,
    size: number,
    filter?: FilterField[]
  ): Promise<Fisher[]> {
    return this.wrapRequest(async () => {
      const { meta, data } = await getFishers({
        limit: size,
        filter,
        paginationToken: this.paginationTokens[page - 1],
      });
      runInAction(() => {
        this.fishers = data;
        this.lastPage = meta.paginationToken ? false : true;
        this.paginationTokens[page] = meta.paginationToken;
      });
      return data;
    });
  }

  async getFisher(fisherId: string): Promise<Fisher> {
    return this.wrapRequest(async () => {
      const fisher = await getFisher(fisherId);
      runInAction(() => {
        this.fisher = fisher;
      });
      return fisher;
    });
  }

  async saveFisher(cmd: FisherSaveCommand): Promise<Fisher> {
    return this.wrapRequest(async () => {
      const fisher = await saveFisher(cmd);
      runInAction(() => {
        this.fisher = fisher;
        this.fishers.push(fisher);
      });
      return fisher;
    });
  }

  async updateFisher(
    fisherId: string,
    cmd: FisherUpdateCommand,
    photo?: File
  ): Promise<Fisher> {
    return this.wrapRequest(async () => {
      const fisher = await updateFisher(fisherId, cmd);

      // Upload fisher photo to s3
      if (photo && fisher.photo) {
        await putS3Files([{ url: fisher.photo, file: photo }]);
        fisher.photo = URL.createObjectURL(photo);
      }

      runInAction(() => {
        this.fisher = fisher;
        this.fishers = this.fishers.map((it) =>
          it.id === fisher.id ? { ...fisher } : it
        );
      });

      return fisher;
    });
  }

  async deleteFisher(fisherId: string): Promise<void> {
    return this.wrapRequest(async () => {
      await deleteFisher(fisherId);
      runInAction(() => {
        this.fisher = undefined;
        this.fishers = this.fishers.filter((it) => it.id !== fisherId);
      });
    });
  }
}
