import { makeAutoObservable, runInAction } from "mobx";
import { Certifier } from "../models";
import { listCertifiers } from "../services";

export default class CertifierStore {
  certifiers: Certifier[] = [];
  state: "pending" | "done" | "error" = "done";
  error = "";

  constructor() {
    makeAutoObservable(this);
  }

  async wrapRequest<T>(body: () => Promise<T>): Promise<T> {
    try {
      this.state = "pending";
      this.error = "";
      const res = await body();
      runInAction(() => {
        this.state = "done";
      });
      return res;
    } catch (err) {
      runInAction(() => {
        this.state = "error";
        this.error = err.message;
      });
      throw err;
    }
  }

  async getCertifiers(): Promise<Certifier[]> {
    return this.wrapRequest(async () => {
      const data = await listCertifiers();
      runInAction(() => {
        this.certifiers = data;
      });
      return data;
    });
  }
}
