import i18next from "i18next";

export type FilterFieldOperator =
  | "eq"
  | "ne"
  | "lt"
  | "le"
  | "gt"
  | "ge"
  | "contains"
  | "begins_with"
  | "between"
  | "in";

export const filterfieldOperatorOptions = [
  { value: "eq", name: i18next.t("shared.listFilters.eq") },
  { value: "ne", name: i18next.t("shared.listFilters.ne") },
  { value: "lt", name: i18next.t("shared.listFilters.lt") },
  { value: "le", name: i18next.t("shared.listFilters.le") },
  { value: "gt", name: i18next.t("shared.listFilters.gt") },
  { value: "ge", name: i18next.t("shared.listFilters.ge") },
  { value: "contains", name: i18next.t("shared.listFilters.contains") },
  { value: "begins_with", name: i18next.t("shared.listFilters.begins_with") },
  { value: "between", name: i18next.t("shared.listFilters.between") },
  { value: "in", name: i18next.t("shared.listFilters.in") },
];

export const filterfieldOperators = filterfieldOperatorOptions.map(
  (it) => it.value
);

export interface FilterField {
  field: string;
  operator: FilterFieldOperator;
  values: string[];
}

export type SortType = "asc" | "desc";

export interface PageRequest {
  limit: number;
  sort?: SortType;
  filter?: FilterField[];
  paginationToken?: string;
}

export interface PageResponseMeta {
  paginationToken?: string;
}

export interface PageResponse<T> {
  meta: PageResponseMeta;
  data: T[];
}
