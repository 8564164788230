import React, { ReactNode } from "react";
import {
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from "@chakra-ui/react";
import { Field, FieldProps, FieldInputProps } from "formik";
import get from "lodash.get";

export interface FormFieldProps {
  name: string;
  type: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  children?(props: FieldInputProps<string>): ReactNode;
}

/**
 * lodash.get used for cases where errors/touched are in form.errors.translations[0].language
 */
export const FormField: React.FC<FormFieldProps> = ({
  name,
  type,
  label,
  placeholder,
  disabled,
  children,
}) => {
  const randomId = `${name}-${Math.random().toString(36).substring(7)}`;

  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => (
        <FormControl
          isInvalid={
            get(form.errors, name) && get(form.touched, name) ? true : false
          }>
          {label && <FormLabel htmlFor={randomId}>{label}</FormLabel>}
          <FormErrorMessage my={2}>{get(form.errors, name)}</FormErrorMessage>
          {children ? (
            children(field)
          ) : (
            <Input
              {...field}
              type={type}
              id={randomId}
              value={get(form.values, name)}
              placeholder={placeholder}
              disabled={disabled}
            />
          )}
        </FormControl>
      )}
    </Field>
  );
};
