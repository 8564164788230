import { Fisher, FishSpecies, FoodBusinessStatus } from "./";

export interface FisherSaveCommand {
  foodBusinessId: string;
  name: string;
}

export interface FisherUpdateCommand {
  status?: FoodBusinessStatus;
  foodBusinessId?: string;
  name?: string;
  photo?: string;
}

export const fisherToCmd = (fisher: Fisher): FisherUpdateCommand => ({
  status: fisher.status,
  foodBusinessId: fisher.foodBusinessId,
  name: fisher.name,
});

export interface FishSpeciesSaveCommand {
  species: string;
  scientificName: string;
  commonName: string;
  description: string;
}

export interface FishSpeciesUpdateCommand {
  status?: FoodBusinessStatus;
  species?: string;
  scientificName?: string;
  commonName?: string;
  description?: string;
}

export const fishSpeciesToCmd = (
  fishSpecies: FishSpecies
): FishSpeciesUpdateCommand => ({
  status: fishSpecies.status,
  species: fishSpecies.species,
  scientificName: fishSpecies.scientificName,
  commonName: fishSpecies.commonName,
  description: fishSpecies.description,
});
