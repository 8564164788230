import React from "react";
import { StoreContext } from "./context";
import { TagTypeStore } from "app/tags/stores";
import {
  FisherStore,
  FishesSpeciesStore,
  TradeUnitStore,
} from "app/fishery/stores";
import TagStore from "app/tags/stores/TagStore";
import CertifierStore from "app/users/stores";

export const store = {
  tagTypeStore: new TagTypeStore(),
  fisherStore: new FisherStore(),
  fishesSpeciesStore: new FishesSpeciesStore(),
  tagStore: new TagStore(),
  tradeUnitStore: new TradeUnitStore(),
  certifierStore: new CertifierStore(),
};

interface Props {
  children: React.ReactNode;
}

export const StoreProvider: React.FC<Props> = ({ children }) => (
  <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
);
