import axios from "network";
import Axios from "axios";
import { PageRequest, PageResponse } from "network/models";
import { Fisher, FishSpecies, TradeUnit } from "../models";
import {
  FisherSaveCommand,
  FisherUpdateCommand,
  FishSpeciesSaveCommand,
  FishSpeciesUpdateCommand,
} from "../models/commands";
import { stringifyPageRequest } from "network/utils";

/**
 * Fishers
 */
export const getFishers = async (
  pageRequest?: PageRequest
): Promise<PageResponse<Fisher>> => {
  return axios
    .get(`/fishers${stringifyPageRequest(pageRequest)}`)
    .then((response) => ({
      meta: {
        totalElements: response.data.meta.estimatedCount,
        paginationToken: response.data.meta.paginationToken,
      },
      data: response.data.data,
    }));
};

export const getFisher = async (fisherId: string): Promise<Fisher> => {
  return axios.get(`/fishers/${fisherId}`).then((response) => response.data);
};

export const saveFisher = async (cmd: FisherSaveCommand): Promise<Fisher> => {
  return axios.post(`/fishers`, { ...cmd }).then((response) => response.data);
};

export const updateFisher = async (
  fisherId: string,
  cmd: FisherUpdateCommand
): Promise<Fisher> => {
  return axios
    .put(`/fishers/${fisherId}`, { ...cmd })
    .then((response) => response.data);
};

export const deleteFisher = async (fisherId: string): Promise<void> => {
  return axios.delete(`/fishers/${fisherId}`);
};

/**
 * FishSpecies
 */
export const getFishesSpecies = async (
  pageRequest?: PageRequest
): Promise<PageResponse<FishSpecies>> => {
  return axios
    .get(`/fishSpecies${stringifyPageRequest(pageRequest)}`)
    .then((response) => ({
      meta: {
        totalElements: response.data.meta.estimatedCount,
        paginationToken: response.data.meta.paginationToken,
      },
      data: response.data.data,
    }));
};

export const getFishSpecies = async (
  fishSpeciesId: string
): Promise<FishSpecies> => {
  return axios
    .get(`/fishSpecies/${fishSpeciesId}`)
    .then((response) => response.data);
};

export const saveFishSpecies = async (
  cmd: FishSpeciesSaveCommand
): Promise<FishSpecies> => {
  return axios
    .post(`/fishSpecies`, { ...cmd })
    .then((response) => response.data);
};

export const updateFishSpecies = async (
  fishSpeciesId: string,
  cmd: FishSpeciesUpdateCommand
): Promise<FishSpecies> => {
  return axios
    .put(`/fishSpecies/${fishSpeciesId}`, { ...cmd })
    .then((response) => response.data);
};

export const deleteFishSpecies = async (
  fishSpeciesId: string
): Promise<void> => {
  return axios.delete(`/fishSpecies/${fishSpeciesId}`);
};

/**
 * TradeUnits
 */
export const getTradeUnits = async (
  pageRequest?: PageRequest
): Promise<PageResponse<TradeUnit>> => {
  return axios
    .get(`/tradeUnit${stringifyPageRequest(pageRequest)}`)
    .then((response) => ({
      meta: {
        totalElements: response.data.meta.estimatedCount,
        paginationToken: response.data.meta.paginationToken,
      },
      data: response.data.data,
    }));
};

export const getTradeUnit = async (tradeUnitId: string): Promise<TradeUnit> => {
  const response = await axios.get(`/tradeUnit/${tradeUnitId}`);
  return response.data;
};

export const getTradeUnitHistory = async (
  tradeUnitId: string
): Promise<TradeUnit[]> => {
  const response = await axios.get(`/tradeUnit/${tradeUnitId}/history`);
  return response.data;
};

/**
 * Others
 */
export const putS3Files = async (
  files: { url: string; file: File }[]
): Promise<void> => {
  const instance = Axios.create(); // instance without authentication
  await Promise.all(files.map((it) => instance.put(it.url, it.file)));
};
