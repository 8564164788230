import React from "react";
import { Box, Link, Input, Button, InputProps } from "@chakra-ui/react";

interface Props extends InputProps {
  name: string;
  label: string;
  onFilesChange: (name: string, files: File[]) => void;
  value?: string;
  disabled?: boolean;
  multiple?: boolean;
}

export const FileInput: React.FC<Props> = ({
  name,
  value,
  label,
  onFilesChange,
  disabled,
  multiple,
  height,
  ...rest
}) => {
  const id = `${name}-${Math.random().toString(36).substring(7)}`;
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const files: File[] = [];

    if (event.target.files) {
      for (let i = 0; i < event.target.files?.length; i++) {
        files.push(event.target.files[i]);
      }
    }

    onFilesChange(name, files);
    event.target.value = "";
  };

  return (
    <Box>
      <label htmlFor={id}>
        <Button as={Link} {...(rest as any)}>
          {label}
        </Button>
      </label>
      <Input
        id={id}
        name={name}
        type="file"
        value={value}
        onChange={onChange}
        disabled={disabled}
        multiple={multiple}
        sx={{ display: "none" }}
      />
    </Box>
  );
};
