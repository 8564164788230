import React from "react";
import { ContentArea, Link } from "app/shared";
import {
  Button,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { TagTypeList } from "app/tags";

const TagTypesPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ContentArea>
      <Breadcrumb mb={4} fontWeight="medium" fontSize="lg">
        <BreadcrumbItem>
          <BreadcrumbLink href="/types" as={Link}>
            {t("pages.breadcrumbs.tagTypes")}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Box mb={4}>
        <Button href="/types/create" as={Link}>
          {t("pages.tagTypesPage.add")}
        </Button>
      </Box>
      <TagTypeList />
    </ContentArea>
  );
};

export default TagTypesPage;
