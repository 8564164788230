import { makeAutoObservable, runInAction } from "mobx";
import { FilterField } from "network/models";
import { BulkTagResponse, Tag, TagView } from "../models";
import {
  assignTag,
  returnTag,
  listTags,
  saveTags,
  getTagViews,
} from "../services";

export default class TagStore {
  tags: Tag[] = [];
  lastPage = true;
  state: "pending" | "done" | "error" = "done";
  error = "";
  paginationTokens: Record<number, string | undefined> = {};

  constructor() {
    makeAutoObservable(this);
  }

  async wrapRequest<T>(body: () => Promise<T>): Promise<T> {
    try {
      runInAction(() => {
        this.state = "pending";
      });
      this.error = "";
      const res = await body();
      runInAction(() => {
        this.state = "done";
      });
      return res;
    } catch (err) {
      runInAction(() => {
        this.state = "error";
        this.error = err.message;
      });
      throw err;
    }
  }

  async getTags(
    page: number,
    size: number,
    filter?: FilterField[]
  ): Promise<Tag[]> {
    return this.wrapRequest(async () => {
      const { meta, data } = await listTags({
        limit: size,
        filter,
        paginationToken: this.paginationTokens[page - 1],
      });
      runInAction(() => {
        this.tags = data;
        this.lastPage = meta.paginationToken ? false : true;
        this.paginationTokens[page] = meta.paginationToken;
      });
      return data;
    });
  }

  async saveTags(file: File): Promise<BulkTagResponse> {
    return this.wrapRequest(async () => {
      const res = await saveTags(file);
      runInAction(() => {
        this.tags = res.tags;
        this.error = res.errors.join(",");
      });
      return res;
    });
  }

  async assignTagToUser(tagId: string, userId: string): Promise<Tag> {
    return this.wrapRequest(async () => {
      const res = await assignTag(tagId, userId);
      runInAction(() => {
        const workingCopy = this.tags.concat([]);
        const tagIdx = workingCopy.findIndex((t) => t.id === res.id);
        if (tagIdx !== -1) {
          workingCopy[tagIdx] = res;
        } else {
          workingCopy.push(res);
        }
        this.tags = workingCopy;
      });
      return res;
    });
  }

  async unassignTagFromUser(tagId: string): Promise<Tag> {
    return this.wrapRequest(async () => {
      const res = await returnTag(tagId);
      runInAction(() => {
        const workingCopy = this.tags.concat([]);
        const tagIdx = workingCopy.findIndex((t) => t.id === res.id);
        if (tagIdx !== -1) {
          workingCopy[tagIdx] = res;
        } else {
          workingCopy.push(res);
        }
        this.tags = workingCopy;
      });
      return res;
    });
  }

  async getTagViews(tagId: string): Promise<TagView[]> {
    return this.wrapRequest(async () => {
      const pageSize = 200;
      const date = new Date();
      date.setMonth(date.getMonth() - 1);
      const filters: FilterField[] = [
        { field: "createdAt", operator: "ge", values: [date.toISOString()] },
      ];
      const { data } = await getTagViews(tagId, {
        limit: pageSize,
        sort: "desc",
        filter: filters,
      });
      return data;
    });
  }
}
