import axios from "network";
import { PageRequest, PageResponse } from "network/models";
import { BulkTagResponse, Tag, TagType, TagView } from "../models";
import { TagTypeSaveCommand, TagTypeUpdateCommand } from "../models/commands";
import { stringifyPageRequest } from "network/utils";

/**
 * Tag types
 */
export const getTagTypes = async (
  pageRequest?: PageRequest
): Promise<PageResponse<TagType>> => {
  return axios
    .get(`/tags/types${stringifyPageRequest(pageRequest)}`)
    .then((response) => ({
      meta: {
        totalElements: response.data.meta.estimatedCount,
        paginationToken: response.data.meta.paginationToken,
      },
      data: response.data.data,
    }));
};

export const getTagType = async (tagTypeId: string): Promise<TagType> => {
  return axios
    .get(`/tags/types/${tagTypeId}`)
    .then((response) => response.data);
};

export const saveTagType = async (
  cmd: TagTypeSaveCommand
): Promise<TagType> => {
  return axios
    .post(`/tags/types`, { ...cmd })
    .then((response) => response.data);
};

export const updateTagType = async (
  tagTypeId: string,
  cmd: TagTypeUpdateCommand
): Promise<TagType> => {
  const response = await axios.put(`/tags/types/${tagTypeId}`, { ...cmd });
  return response.data;
};

export const deleteTagType = async (tagTypeId: string): Promise<void> => {
  return axios.delete(`/tags/types/${tagTypeId}`);
};

/**
 * Tag types
 */
export const listTags = async (
  pageRequest?: PageRequest
): Promise<PageResponse<Tag>> => {
  const response = await axios.get(`/tags${stringifyPageRequest(pageRequest)}`);
  return {
    meta: {
      paginationToken: response.data.meta.paginationToken,
    },
    data: response.data.data,
  };
};

export const saveTags = async (blob: File): Promise<BulkTagResponse> => {
  const response = await axios.post(`/tags`, blob, {
    headers: { "Content-Type": "text/csv" },
  });

  return response.data;
};

export const assignTag = async (
  tagId: string,
  userId: string
): Promise<Tag> => {
  const response = await axios.put(
    `/tags/${tagId}/assign`,
    { userId },
    {
      headers: { "Content-Type": "application/json" },
    }
  );
  return response.data;
};

export const returnTag = async (tagId: string): Promise<Tag> => {
  const response = await axios.put(
    `/tags/${tagId}/return`,
    {},
    {
      headers: { "Content-Type": "application/json" },
    }
  );
  return response.data;
};

/**
 * Tag Views
 */
export const getTagViews = async (
  tagId: string,
  pageRequest?: PageRequest
): Promise<PageResponse<TagView>> => {
  return axios
    .get(`/tags/${tagId}/view${stringifyPageRequest(pageRequest)}`)
    .then((response) => ({
      meta: {
        totalElements: response.data.meta.estimatedCount,
        paginationToken: response.data.meta.paginationToken,
      },
      data: response.data.data,
    }));
};
