import React from "react";
import { ContentArea, Link } from "app/shared";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { TradeUnitList } from "app/fishery";

const TradeUnitsPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ContentArea>
      <Breadcrumb mb={4} fontWeight="medium" fontSize="lg">
        <BreadcrumbItem>
          <BreadcrumbLink href="/trade-units" as={Link}>
            {t("pages.breadcrumbs.tradeUnits")}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <TradeUnitList />
    </ContentArea>
  );
};

export default TradeUnitsPage;
