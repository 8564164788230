import { theme } from "styles";

export const tableStyle = `
  text-align: center;
  word-break: break-all;

  table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
  }

  table th, td {
    padding: 12px;
  }

  table tr {
    border-bottom: 1px;
    border-style: solid;
    border-color: ${theme.colors.brand[500]};
  }

  table thead tr {
    border-top: 1px;
    border-bottom: 1px;
    border-style: solid;
    border-color: ${theme.colors.brand[500]};
  }

  table tbody tr:nth-of-type(odd) {
    background: rgba(0, 0, 0, .05);
  }

  div.p-datatable-wrapper th.p-sortable-column {
    cursor: pointer;
  }

  div.p-datatable-wrapper th.p-highlight span.p-column-title {
    padding-right: 5px;
  }

  div.p-datatable-wrapper th.p-highlight span.pi-sort-amount-up-alt:after {
    content: '↑';
  }

  div.p-datatable-wrapper th.p-highlight span.pi-sort-amount-down:after {
    content: '↓';
  }

  div.p-paginator {
    display: flex;
    justify-content: space-between;
    padding: 12px;
  }

  div.p-paginator button.p-paginator-element span.pi-angle-left:after {
    content: '←';
  }

  div.p-paginator button.p-paginator-element span.pi-angle-right:after {
    content: '→';
  }

  div.p-paginator button.p-paginator-element:disabled {
    display: none;
  }
`;
