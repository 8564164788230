import {
  Alert,
  AlertIcon,
  Box,
  Spinner,
  Heading,
  Text,
  Flex,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Avatar,
} from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useStoreContext } from "store";
import { useTranslation } from "react-i18next";
import {
  NonConformityDetectedEvent,
  TradeUnit,
  TradeUnitRejectedEvent,
} from "../models";
import { ClassNames } from "@emotion/react";
import { tableStyle } from "app/shared/tableStyle";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import GoogleMapReact from "google-map-react";
import { Marker } from "./Marker";
import { environment } from "configurations";
import { TagView } from "app/tags/models";

interface Props {
  id: string;
}

const TradeUnitView: React.FC<Props> = ({ id }) => {
  const { t } = useTranslation();
  const { tradeUnitStore, fisherStore, tagStore } = useStoreContext();
  const [history, setHistory] = useState<TradeUnit[]>([]);
  const [views, setViews] = useState<TagView[]>([]);

  useEffect(() => {
    tradeUnitStore.getTradeUnit(id).then((tradeUnit) => {
      fisherStore.getFisher(tradeUnit.originInfo.origin.id);

      tagStore.getTagViews(tradeUnit.tagId).then((views) => setViews(views));
    });
    tradeUnitStore
      .getTradeUnitHistory(id)
      .then((history) => setHistory(history));
  }, [tradeUnitStore, fisherStore, tagStore, id]);

  const rowClassName = (tradeUnit: TradeUnit) => {
    return tradeUnit.status === "Rejected"
      ? { "column-datagrid-odd": true }
      : { "column-datagrid": true };
  };

  // Prevent initiating form without trade unit initial values
  if (
    tradeUnitStore.tradeUnit?.id !== id ||
    fisherStore.fisher?.id !== tradeUnitStore.tradeUnit?.originInfo?.origin?.id
  ) {
    return <Spinner />;
  }

  const rejections = tradeUnitStore.tradeUnit.events.filter(
    (it) =>
      it.type === "NonConformityDetected" || it.type === "TradeUnitRejected"
  );

  return (
    <Box>
      {tradeUnitStore.state === "pending" && <Spinner mb={4} />}
      {tradeUnitStore.error?.length > 0 && (
        <Alert status="error" mb={4}>
          <AlertIcon />
          {tradeUnitStore.error}
        </Alert>
      )}
      <Tabs variant="enclosed">
        <TabList>
          <Tab>{t("fishery.tradeUnitView.details")}</Tab>
          <Tab>{t("fishery.tradeUnitView.history")}</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Flex flexDirection={["column", null, "row"]}>
              <Box width={["100%", null, "50%"]}>
                <Heading size="lg">
                  {t("fishery.tradeUnitView.tagInformation")}
                </Heading>
                <Text>
                  <b>{t("fishery.tradeUnitView.status")}:</b>{" "}
                  {tradeUnitStore.tradeUnit.status}
                </Text>
                <Text>
                  <b>{t("fishery.tradeUnitView.tagNumber")}:</b>{" "}
                  {tradeUnitStore.tradeUnit.tagNumber}
                </Text>
                <Text>
                  <b>{t("fishery.tradeUnitView.tagId")}:</b>{" "}
                  {tradeUnitStore.tradeUnit.tagId}
                </Text>

                {rejections.length > 0 && (
                  <>
                    <Heading size="lg" mt={4}>
                      {t("fishery.tradeUnitView.nonConformities")}
                    </Heading>
                    {rejections.map((it) => {
                      let text = "";

                      if (it.type === "NonConformityDetected") {
                        text = (it as NonConformityDetectedEvent).evidences
                          .map((e) => e.comment)
                          .join(",");
                      } else if (it.type === "TradeUnitRejected") {
                        text = (it as TradeUnitRejectedEvent).comment;
                      }

                      return (
                        <Text>
                          <b>{it.type}:</b> {text}
                        </Text>
                      );
                    })}
                  </>
                )}

                <Heading size="lg" mt={4}>
                  {t("fishery.tradeUnitView.captureInformation")}
                </Heading>
                <Flex alignItems="center" my={4}>
                  <Avatar src={fisherStore.fisher.photo} size="lg" mr={3} />
                  <Box>
                    {fisherStore.fisher.name} (
                    {fisherStore.fisher.foodBusinessId})
                  </Box>
                </Flex>
                <Text>
                  <b>{t("fishery.tradeUnitView.date")}:</b>{" "}
                  {tradeUnitStore.tradeUnit.originInfo.dateOfCapture}
                </Text>
                <Text>
                  <b>{t("fishery.tradeUnitView.fishSpecies")}:</b>{" "}
                  {tradeUnitStore.tradeUnit.originInfo.fishSpecie.commonName}
                </Text>
                <Text>
                  <b>{t("fishery.tradeUnitView.netWeight")}:</b>{" "}
                  {tradeUnitStore.tradeUnit.originInfo.netWeight}
                </Text>
                <Text>
                  <b>{t("fishery.tradeUnitView.fishingMethod")}:</b>{" "}
                  {tradeUnitStore.tradeUnit.originInfo.fishingMethod}
                </Text>

                <Heading size="lg" mt={4}>
                  {t("fishery.tradeUnitView.certificationInformation")}
                </Heading>
                <Text>
                  <b>{t("fishery.tradeUnitView.by")}:</b>{" "}
                  {tradeUnitStore.tradeUnit.originInfo.principal.name} (
                  {tradeUnitStore.tradeUnit.originInfo.principal.email})
                </Text>
                <Text>
                  <b>{t("fishery.tradeUnitView.date")}:</b>{" "}
                  {tradeUnitStore.tradeUnit.originInfo.date}
                </Text>
                {tradeUnitStore.tradeUnit.originInfo.tagLocation && (
                  <Text>
                    <b>{t("fishery.tradeUnitView.location")}:</b>{" "}
                    {tradeUnitStore.tradeUnit.originInfo.tagLocation.latitude},{" "}
                    {tradeUnitStore.tradeUnit.originInfo.tagLocation.longitude}
                  </Text>
                )}
              </Box>
              <Box
                width={["100%", null, "50%"]}
                height="450px"
                mt={[7, null, 0]}>
                <GoogleMapReact
                  bootstrapURLKeys={{ key: environment.googleMapsKey }}
                  defaultCenter={{
                    lat:
                      tradeUnitStore.tradeUnit.originInfo.tagLocation
                        ?.latitude ?? 41.688231,
                    lng:
                      tradeUnitStore.tradeUnit.originInfo.tagLocation
                        ?.longitude ?? -8.790941,
                  }}
                  defaultZoom={9}>
                  {tradeUnitStore.tradeUnit.originInfo.tagLocation && (
                    <Marker
                      lat={
                        tradeUnitStore.tradeUnit.originInfo.tagLocation.latitude
                      }
                      lng={
                        tradeUnitStore.tradeUnit.originInfo.tagLocation
                          .longitude
                      }>
                      X
                    </Marker>
                  )}
                  {views
                    .filter((it) => it.latitude && it.longitude)
                    .map((it) => (
                      <Marker
                        lat={it.latitude!!}
                        lng={it.longitude!!}
                        backgroundColor="red">
                        X
                      </Marker>
                    ))}
                </GoogleMapReact>
              </Box>
            </Flex>
          </TabPanel>

          <TabPanel>
            <ClassNames>
              {({ css }) => (
                <DataTable
                  rowClassName={rowClassName}
                  totalRecords={history.length}
                  rows={history.length}
                  value={history}
                  emptyMessage={t("fishery.tradeUnitView.noResults")}
                  loading={tradeUnitStore.state === "pending"}
                  className={css`
                    ${tableStyle}
                  `}>
                  <Column
                    header={t("fishery.tradeUnitView.history")}
                    body={(tradeUnit: TradeUnit) => (
                      <Box textAlign="left" sx={{ overflow: "hidden" }}>
                        <pre>{JSON.stringify(tradeUnit, null, 2)}</pre>
                      </Box>
                    )}
                  />
                </DataTable>
              )}
            </ClassNames>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default observer(TradeUnitView);
