import { FileInput } from "app/shared";
import React, { useState } from "react";
import { useStoreContext } from "store";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { BulkTagResponse } from "../models";
import { Box, Grid, Text } from "@chakra-ui/react";

const TagCreateForm: React.FC = () => {
  const { tagStore } = useStoreContext();
  const [result, setResult] = useState<BulkTagResponse | undefined>(undefined);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { t } = useTranslation();

  const submitFile = async (name: string, files: File[]): Promise<void> => {
    if (files.length && window.confirm(t("tags.createForm.confirm"))) {
      setSubmitting(true);
      const file = files[0];
      setResult(await tagStore.saveTags(file));
      setSubmitting(false);
    }
  };

  return (
    <>
      <Grid templateColumns="repeat(2, 1fr)" gap={6}>
        <Box>
          <Text>{t("tags.createForm.selectFileLabel")}</Text>
          <FileInput
            name="update"
            label={t("tags.createForm.selectFile")}
            onFilesChange={submitFile}
            disabled={submitting}
          />
        </Box>
        <Box>
          <Text>{t("tags.createForm.fileFormat")}</Text>
          <Box bg="#c6c6c6">
            <pre>
              <p>tagId,tagTypeId</p>
              <p>12345,1</p>
            </pre>
          </Box>
        </Box>
      </Grid>
      {submitting && <Text>{t("tags.createForm.submitting")}</Text>}
      {result && (
        <Box>
          <Text>
            {t("tags.createForm.numCreatedTags")} {result.tags.length}
          </Text>
          {result.errors.length === 0 && (
            <Text>{t("tags.createForm.noErrors")}</Text>
          )}
          {result.errors.length > 0 && (
            <>
              <Text>{t("tags.createForm.bulkErrors")}</Text>
              <ul>
                {result.errors.map((err, i) => (
                  <li key={i}>
                    {t("tags.createForm.errorLine")}
                    {err.line}: {err.message}
                  </li>
                ))}
              </ul>
            </>
          )}
          <Text></Text>
          {result.tags.map}
        </Box>
      )}
    </>
  );
};

export default observer(TagCreateForm);
