import React, { ReactNode } from "react";
import { Link as ChakraLink, LinkProps } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

interface Props extends LinkProps {
  href: string;
  children: ReactNode;
}

export const Link: React.FC<Props> = ({ children, href, ...rest }) => {
  if (href.startsWith("http")) {
    return (
      <ChakraLink href={href} {...rest}>
        {children}
      </ChakraLink>
    );
  } else {
    return (
      <ChakraLink as={RouterLink} to={href} {...rest}>
        {children}
      </ChakraLink>
    );
  }
};
