import React from "react";
import { Box, BoxProps } from "@chakra-ui/react";
import { Select } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface Props extends BoxProps {
  onPageSizeChange: (pageSize: number) => void;
  options?: number[];
  defaultValue?: number;
}

export const ListPaginator: React.FC<Props> = ({
  onPageSizeChange,
  options,
  defaultValue,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <Box {...rest}>
      <Select
        defaultValue={defaultValue}
        placeholder={t("shared.listPaginator.placeholder")}
        onChange={(event) => {
          const pageSize = event.target.value;
          if (pageSize?.length) {
            onPageSizeChange(parseInt(pageSize, 10));
          }
        }}>
        {options ??
          [25, 50, 100, 200, 500, 1000, 2000].map((it) => (
            <option key={it} value={it}>
              {it}
            </option>
          ))}
      </Select>
    </Box>
  );
};
