import * as Yup from "yup";
import i18next from "i18next";

/**
 * Fishers
 */
export const FisherCreateSchema = Yup.object().shape({
  foodBusinessId: Yup.string()
    .min(3, i18next.t("schemas.tooShort"))
    .max(255, i18next.t("schemas.tooLong"))
    .required(i18next.t("schemas.required")),
  name: Yup.string()
    .min(3, i18next.t("schemas.tooShort"))
    .max(255, i18next.t("schemas.tooLong"))
    .required(i18next.t("schemas.required")),
});

export const FisherUpdateSchema = Yup.object().shape({
  status: Yup.string().oneOf(["active"]),
  foodBusinessId: Yup.string()
    .min(3, i18next.t("schemas.tooShort"))
    .max(255, i18next.t("schemas.tooLong")),
  name: Yup.string()
    .min(3, i18next.t("schemas.tooShort"))
    .max(255, i18next.t("schemas.tooLong")),
});

/**
 * FishSpecies
 */
export const FishSpeciesCreateSchema = Yup.object().shape({
  species: Yup.string()
    .min(3, i18next.t("schemas.tooShort"))
    .max(255, i18next.t("schemas.tooLong"))
    .required(i18next.t("schemas.required")),
  scientificName: Yup.string()
    .min(3, i18next.t("schemas.tooShort"))
    .max(255, i18next.t("schemas.tooLong"))
    .required(i18next.t("schemas.required")),
  commonName: Yup.string()
    .min(3, i18next.t("schemas.tooShort"))
    .max(255, i18next.t("schemas.tooLong"))
    .required(i18next.t("schemas.required")),
  description: Yup.string()
    .min(3, i18next.t("schemas.tooShort"))
    .max(255, i18next.t("schemas.tooLong"))
    .required(i18next.t("schemas.required")),
});

export const FishSpeciesUpdateSchema = Yup.object().shape({
  status: Yup.string().oneOf(["active"]),
  species: Yup.string()
    .min(3, i18next.t("schemas.tooShort"))
    .max(255, i18next.t("schemas.tooLong")),
  scientificName: Yup.string()
    .min(3, i18next.t("schemas.tooShort"))
    .max(255, i18next.t("schemas.tooLong")),
  commonName: Yup.string()
    .min(3, i18next.t("schemas.tooShort"))
    .max(255, i18next.t("schemas.tooLong")),
  description: Yup.string()
    .min(3, i18next.t("schemas.tooShort"))
    .max(255, i18next.t("schemas.tooLong")),
});
