import { ColorMode, extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";

const breakpoints = createBreakpoints({
  sm: "544px",
  md: "768px",
  lg: "1024px",
  xl: "1280px",
});

const colorsBrand = {
  100: "#ffffff",
  200: "#c2c2c2",
  300: "#acacac",
  400: "#979797",
  500: "#818181",
  600: "#6c6c6c",
  700: "#565656",
  800: "#404040",
  900: "#2b2b2b",
};

const overrides = {
  config: {
    useSystemColorMode: false,
    initialColorMode: "light" as ColorMode,
  },
  styles: {
    global: {
      body: {
        bg: colorsBrand[100],
      },
    },
  },
  breakpoints,
  sizes: {
    maxWidth: "1440px",
    navbarHeight: { sm: "50px", lg: "80px" },
  },
  colors: {
    brand: {
      ...colorsBrand,
    },
  },
  mediaQueries: {
    sm: `@media screen and (min-width: ${breakpoints.sm})`,
    md: `@media screen and (min-width: ${breakpoints.md})`,
    lg: `@media screen and (min-width: ${breakpoints.lg})`,
  },
  components: {
    Button: {
      defaultProps: {
        colorScheme: "black",
        variant: "outline",
      },
    },
    Input: {
      baseStyle: {
        background: "black",
      },
      variants: {
        outline: {
          field: {
            borderColor: "black",
            _hover: {
              borderColor: colorsBrand[600],
            },
          },
        },
      },
    },
    Select: {
      baseStyle: {
        background: "black",
      },
      variants: {
        outline: {
          field: {
            borderColor: "black",
            _hover: {
              borderColor: colorsBrand[600],
            },
          },
        },
      },
    },
  },
};

export const theme = extendTheme(overrides);
