import React, { useState } from "react";
import { Image as ChakraImage, ImageProps, Skeleton } from "@chakra-ui/react";

export const Image: React.FC<ImageProps> = ({
  boxSize,
  mx,
  margin,
  my,
  ...props
}) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <Skeleton
      isLoaded={loaded}
      boxSize={boxSize}
      mx={mx}
      margin={margin}
      my={my}
      startColor="brand.100"
      endColor="brand.500">
      <ChakraImage
        {...props}
        boxSize={boxSize}
        onLoad={() => setLoaded(true)}
        mx="auto"
      />
    </Skeleton>
  );
};
