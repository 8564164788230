module.exports = {
  production: true,
  api: "https://api.doriominho.pt",
  amplify: {
    region: "eu-west-1",
    userPoolId: "eu-west-1_QUZV8Ls8K",
    userPoolWebClientId: "3hdu7ocvjb9npuaveumhliie14",
  },
  googleMapsKey: "AIzaSyDJybZxEt4WQ9gXBRtavhu3NORm-lzhjow",
};
