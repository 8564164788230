import { TagType, TagTypeStatus } from "./";

export interface TagTypeSaveCommand {
  id: string;
  name: string;
  urlMaxSize: number;
  uidSize: number;
}

export interface TagTypeUpdateCommand {
  status?: TagTypeStatus;
  name?: string;
  urlMaxSize?: number;
  uidSize?: number;
}

export const tagTypeToCmd = (tagType: TagType): TagTypeUpdateCommand => ({
  status: tagType.status,
  name: tagType.name,
  urlMaxSize: tagType.urlMaxSize,
  uidSize: tagType.uidSize,
});
