import { Grid } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { theme } from "styles/theme";

const ContentArea = styled(Grid)`
  min-height: calc(100vh - ${theme.sizes.navbarHeight.sm});
  ${theme.mediaQueries.lg} {
    min-height: calc(100vh - ${theme.sizes.navbarHeight.lg});
  }
`;

ContentArea.defaultProps = {
  gridArea: "content",
  gridAutoRows: "min-content", // to disable rows height dynamic sizing
  py: 5,
};

export default ContentArea;
