import {
  Button,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Box,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "app/shared";
import { HamburgerIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
import Auth from "@aws-amplify/auth";
import { observer } from "mobx-react-lite";

const BurgerMenuBase: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Menu>
      <MenuButton as={Button} p={0} colorScheme="black" variant="outline">
        <HamburgerIcon />
      </MenuButton>
      <MenuList>
        <MenuGroup title={t("navbar.menu.fishery")} fontSize="lg">
          <Link href="/trade-units">
            <MenuItem>{t("navbar.menu.tradeUnits")}</MenuItem>
          </Link>
          <Link href="/fish-species">
            <MenuItem>{t("navbar.menu.fishesSpecies")}</MenuItem>
          </Link>
          <Link href="/fishers">
            <MenuItem>{t("navbar.menu.fishers")}</MenuItem>
          </Link>
        </MenuGroup>
        <MenuGroup title={t("navbar.menu.tags")} fontSize="lg">
          <Link href="/tags">
            <MenuItem>{t("navbar.menu.tags")}</MenuItem>
          </Link>
          <Link href="/types">
            <MenuItem>{t("navbar.menu.tagTypes")}</MenuItem>
          </Link>
        </MenuGroup>
        <MenuItem>
          <Button
            as={Box}
            onClick={async () => {
              await Auth.signOut();
              window.location.reload(); // force page refresh
            }}>
            {t("navbar.menu.signOut")}
          </Button>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export const BurgerMenu = observer(BurgerMenuBase);
