import { makeAutoObservable, runInAction } from "mobx";
import { FilterField } from "network/models";
import { TradeUnit } from "../models";
import { getTradeUnits, getTradeUnit, getTradeUnitHistory } from "../services";

export default class TradeUnitStore {
  tradeUnits: TradeUnit[] = [];
  lastPage = true;
  tradeUnit?: TradeUnit;
  state: "pending" | "done" | "error" = "done";
  error = "";
  paginationTokens: Record<number, string | undefined> = {};

  constructor() {
    makeAutoObservable(this);
  }

  async wrapRequest<T>(body: () => Promise<T>): Promise<T> {
    try {
      this.state = "pending";
      this.error = "";
      const res = await body();
      runInAction(() => {
        this.state = "done";
      });
      return res;
    } catch (err) {
      runInAction(() => {
        this.state = "error";
        this.error = err.message;
      });
      throw err;
    }
  }

  async getTradeUnits(
    page: number,
    size: number,
    filter?: FilterField[]
  ): Promise<TradeUnit[]> {
    return this.wrapRequest(async () => {
      const { meta, data } = await getTradeUnits({
        limit: size,
        filter,
        paginationToken: this.paginationTokens[page - 1],
      });
      runInAction(() => {
        this.tradeUnits = data;
        this.lastPage = meta.paginationToken ? false : true;
        this.paginationTokens[page] = meta.paginationToken;
      });
      return data;
    });
  }

  async getTradeUnit(tradeUnitId: string): Promise<TradeUnit> {
    // Get from cache
    const cachedTradeUnit = this.tradeUnits.find((it) => it.id === tradeUnitId);
    if (cachedTradeUnit) {
      this.tradeUnit = cachedTradeUnit;
      return cachedTradeUnit;
    }

    return this.wrapRequest(async () => {
      const tradeUnit = await getTradeUnit(tradeUnitId);
      runInAction(() => {
        this.tradeUnit = tradeUnit;
      });
      return tradeUnit;
    });
  }

  async getTradeUnitHistory(tradeUnitId: string): Promise<TradeUnit[]> {
    return this.wrapRequest(async () => {
      return await getTradeUnitHistory(tradeUnitId);
    });
  }
}
