import * as Yup from "yup";
import i18next from "i18next";
import { filterfieldOperators } from "network/models";

export const FiltersSchema = Yup.object().shape({
  filters: Yup.array()
    .of(
      Yup.object().shape({
        field: Yup.string()
          .min(1, i18next.t("schemas.tooShort"))
          .max(50, i18next.t("schemas.tooLong"))
          .required(i18next.t("schemas.required")),
        operator: Yup.string()
          .oneOf(filterfieldOperators)
          .required(i18next.t("schemas.required")),
        values: Yup.array()
          .min(1, i18next.t("schemas.tooShort"))
          .of(
            Yup.string()
              .min(1, i18next.t("schemas.tooShort"))
              .required(i18next.t("schemas.required"))
          )
          .required(i18next.t("schemas.required")),
      })
    )
    .required(i18next.t("schemas.required")),
});
