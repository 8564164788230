import {
  Button,
  ButtonGroup,
  Alert,
  AlertIcon,
  Box,
  Spinner,
} from "@chakra-ui/react";
import { FormField } from "app/shared";
import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useStoreContext } from "store";
import { TagTypeCreateSchema, TagTypeUpdateSchema } from "../models/schemas";
import {
  TagTypeSaveCommand,
  tagTypeToCmd,
  TagTypeUpdateCommand,
} from "../models/commands";
import { useTranslation } from "react-i18next";
import { Prompt } from "react-router-dom";

interface Props {
  id: string;
  onCreate: (tagTypeId: string) => void;
  onDelete: () => void;
}

const TagTypeEditor: React.FC<Props> = ({ id, onCreate, onDelete }) => {
  const { t } = useTranslation();
  const { tagTypeStore } = useStoreContext();
  const mode = id === "create" ? "create" : "update";

  useEffect(() => {
    if (mode === "update") {
      tagTypeStore.getTagType(id);
    }
  }, [tagTypeStore, mode, id]);

  // Prevent initiating form without tag type initial values
  if (mode === "update" && tagTypeStore.tagType?.id !== id) {
    return <Spinner />;
  }

  return (
    <Box>
      {tagTypeStore.state === "pending" && <Spinner mb={4} />}
      {tagTypeStore.error?.length > 0 && (
        <Alert status="error" mb={4}>
          <AlertIcon />
          {tagTypeStore.error}
        </Alert>
      )}
      <Formik
        initialValues={
          mode === "update" && tagTypeStore.tagType
            ? tagTypeToCmd(tagTypeStore.tagType)
            : {}
        }
        validationSchema={
          mode === "create" ? TagTypeCreateSchema : TagTypeUpdateSchema
        }
        onSubmit={async (values, actions) => {
          const promise =
            mode === "create"
              ? tagTypeStore.saveTagType(values as TagTypeSaveCommand)
              : tagTypeStore.updateTagType(id, values as TagTypeUpdateCommand);
          const tagType = await promise;
          actions.resetForm({ values });

          if (mode === "create") {
            onCreate(tagType.id);
          }
        }}>
        {({ isSubmitting, submitForm, setFieldValue, dirty }) => (
          <Form>
            {/* <FormDebug /> */}
            <Prompt
              when={dirty}
              message={t("tags.tagTypeEditor.pendingChanges")}
            />
            <ButtonGroup
              spacing="6"
              isDisabled={isSubmitting || tagTypeStore.state === "pending"}
              mb={4}>
              <Button
                onClick={() => {
                  setFieldValue("status", "active");
                  submitForm();
                }}>
                {t("tags.tagTypeEditor.save")}
              </Button>
              {mode === "update" && (
                <>
                  <Button
                    colorScheme="red"
                    onClick={async () => {
                      if (window.confirm(t("tags.tagTypeEditor.confirm"))) {
                        await tagTypeStore.deleteTagType(id);
                        onDelete();
                      }
                    }}>
                    {t("tags.tagTypeEditor.delete")}
                  </Button>
                </>
              )}
            </ButtonGroup>
            {mode === "create" && (
              <FormField
                name="id"
                type="string"
                label={t("tags.tagTypeEditor.id")}
              />
            )}
            <FormField
              name="name"
              type="string"
              label={t("tags.tagTypeEditor.name")}
            />
            <FormField
              name="urlMaxSize"
              type="number"
              label={t("tags.tagTypeEditor.urlMaxSize")}
            />
            <FormField
              name="uidSize"
              type="number"
              label={t("tags.tagTypeEditor.uidSize")}
            />
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default observer(TagTypeEditor);
