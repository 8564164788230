import { PageRequest } from "network/models";

export const stringifyPageRequest = (pageRequest?: PageRequest): string => {
  const params = [];

  if (pageRequest?.limit) {
    params.push(`page[limit]=${pageRequest?.limit}`);
  }
  if (pageRequest?.filter) {
    params.push(
      ...pageRequest?.filter.map(
        (it) =>
          `filter[${it.field}]=${
            it.operator && it.operator !== "eq" ? `${it.operator}:` : ""
          }${it.values.map((it) => encodeURIComponent(it)).join(",")}`
      )
    );
  }
  if (pageRequest?.paginationToken) {
    params.push(
      `page[token]=${encodeURIComponent(pageRequest?.paginationToken)}`
    );
  }
  if (pageRequest?.sort) {
    params.push(`sort=${pageRequest?.sort}`);
  }

  return params.length ? `?${params.join("&")}` : "";
};
