import { makeAutoObservable, runInAction } from "mobx";
import { FilterField } from "network/models";
import { FishSpecies } from "../models";
import {
  FishSpeciesSaveCommand,
  FishSpeciesUpdateCommand,
} from "../models/commands";
import {
  getFishesSpecies,
  getFishSpecies,
  saveFishSpecies,
  updateFishSpecies,
  deleteFishSpecies,
} from "../services";

export default class FishesSpeciesStore {
  fishesSpecies: FishSpecies[] = [];
  lastPage = true;
  fishSpecies?: FishSpecies;
  state: "pending" | "done" | "error" = "done";
  error = "";
  paginationTokens: Record<number, string | undefined> = {};

  constructor() {
    makeAutoObservable(this);
  }

  async wrapRequest<T>(body: () => Promise<T>): Promise<T> {
    try {
      this.state = "pending";
      this.error = "";
      const res = await body();
      runInAction(() => {
        this.state = "done";
      });
      return res;
    } catch (err) {
      runInAction(() => {
        this.state = "error";
        this.error = err.message;
      });
      throw err;
    }
  }

  async getFishSpecies(
    page: number,
    size: number,
    filter?: FilterField[]
  ): Promise<FishSpecies[]> {
    return this.wrapRequest(async () => {
      const { meta, data } = await getFishesSpecies({
        limit: size,
        filter,
        paginationToken: this.paginationTokens[page - 1],
      });
      runInAction(() => {
        this.fishesSpecies = data;
        this.lastPage = meta.paginationToken ? false : true;
        this.paginationTokens[page] = meta.paginationToken;
      });
      return data;
    });
  }

  async getFish(fishSpeciesId: string): Promise<FishSpecies> {
    // Get from cache
    const cachedFishSpecies = this.fishesSpecies.find(
      (it) => it.id === fishSpeciesId
    );
    if (cachedFishSpecies) {
      this.fishSpecies = cachedFishSpecies;
      return cachedFishSpecies;
    }

    return this.wrapRequest(async () => {
      const fishSpecies = await getFishSpecies(fishSpeciesId);
      runInAction(() => {
        this.fishSpecies = fishSpecies;
      });
      return fishSpecies;
    });
  }

  async saveFishSpecies(cmd: FishSpeciesSaveCommand): Promise<FishSpecies> {
    return this.wrapRequest(async () => {
      const fishSpecies = await saveFishSpecies(cmd);
      runInAction(() => {
        this.fishSpecies = fishSpecies;
        this.fishesSpecies.push(fishSpecies);
      });
      return fishSpecies;
    });
  }

  async updateFishSpecies(
    fishSpeciesId: string,
    cmd: FishSpeciesUpdateCommand
  ): Promise<FishSpecies> {
    return this.wrapRequest(async () => {
      const fishSpecies = await updateFishSpecies(fishSpeciesId, cmd);
      runInAction(() => {
        this.fishSpecies = fishSpecies;
        this.fishesSpecies = this.fishesSpecies.map((it) =>
          it.id === fishSpecies.id ? { ...fishSpecies } : it
        );
      });
      return fishSpecies;
    });
  }

  async deleteFishSpecies(fishSpeciesId: string): Promise<void> {
    return this.wrapRequest(async () => {
      await deleteFishSpecies(fishSpeciesId);
      runInAction(() => {
        this.fishSpecies = undefined;
        this.fishesSpecies = this.fishesSpecies.filter(
          (it) => it.id !== fishSpeciesId
        );
      });
    });
  }
}
