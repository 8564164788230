import React from "react";
import { ContentArea, Link } from "app/shared";
import { RouteComponentProps } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { TradeUnitView } from "app/fishery";

interface Params {
  id: string;
}

const TradeUnitPage: React.FC<RouteComponentProps<Params>> = ({ match }) => {
  const { t } = useTranslation();

  return (
    <ContentArea>
      <Breadcrumb mb={4} fontWeight="medium" fontSize="lg">
        <BreadcrumbItem>
          <BreadcrumbLink href="/trade-units" as={Link}>
            {t("pages.breadcrumbs.tradeUnits")}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href={`/trade-units/${match.params.id}`} as={Link}>
            {t("pages.breadcrumbs.tradeUnit")} {match.params.id}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <TradeUnitView id={match.params.id} />
    </ContentArea>
  );
};

export default TradeUnitPage;
