import { Grid, GridProps } from "@chakra-ui/react";
import styled from "@emotion/styled";
import React from "react";
import { theme } from "styles";

const MainLayoutBase = styled(Grid)<GridProps>`
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "navbar"
    "content";

  ${theme.mediaQueries.md} {
    grid-template-columns: auto minmax(auto, ${theme.sizes.maxWidth}) auto;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      "navbar navbar navbar"
      "...... content ......";
  }
`;

const MainLayout: React.FC<GridProps> = (props) => (
  <MainLayoutBase {...props} />
);

MainLayout.defaultProps = {
  height: "100%",
};

export default MainLayout;
