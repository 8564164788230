import { SmallAddIcon, SmallCloseIcon, ViewIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Flex,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import { ClassNames } from "@emotion/react";
import { ListFilters, ListPaginator } from "app/shared";
import { tableStyle } from "app/shared/tableStyle";
import { observer } from "mobx-react-lite";
import { FilterField } from "network/models";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStoreContext } from "store";
import { Tag, TagStatus } from "../models";

const TagList: React.FC = () => {
  const { t } = useTranslation();
  const { tagStore, certifierStore } = useStoreContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [filters, setFilters] = useState<FilterField[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<string>("");
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [showBulkAssign, setShowBulkAssign] = useState(false);
  const [showBulkUnassign, setShowBulkUnassign] = useState(false);
  const offset = pageNumber * pageSize;
  const totalRecords = tagStore.lastPage ? offset + 1 : offset + pageSize + 1;
  const [tagsToUpdate, setTagsToUpdate] = useState<Tag[]>([]);

  // Load tags
  useEffect(() => {
    tagStore.getTags(pageNumber, pageSize, filters);
  }, [tagStore, pageNumber, pageSize, filters]);

  // Load fishers
  useEffect(() => {
    certifierStore.getCertifiers();
  }, [certifierStore]);

  const rowClassName = (tag: Tag) => {
    return tag.status === "AssignedToUser"
      ? { "column-datagrid-odd": true }
      : { "column-datagrid": true };
  };

  const assignToUser = (tags: Tag[]) => {
    setTagsToUpdate(tags);
    onOpen();
  };

  const doAssignTag = async (selectedUserId: string) => {
    const promises = tagsToUpdate.map((t) =>
      tagStore.assignTagToUser(t.id, selectedUserId)
    );
    await Promise.all(promises);
    clearSelected();
    onClose();
  };

  const unAssignTags = async (tags: Tag[]) => {
    if (window.confirm(t("tags.tagList.confirmUnassign"))) {
      const promises = tags.map((t) => tagStore.unassignTagFromUser(t.id));
      await Promise.all(promises);
      clearSelected();
    }
  };

  const updateTableActions = useCallback((updatedTags: Tag[]) => {
    const tagStatus = updatedTags
      .map((it) => it.status)
      .reduce((acc, currentStatus) => {
        if (acc.has(currentStatus)) {
          acc.set(currentStatus, acc.get(currentStatus)!! + 1);
        } else {
          acc.set(currentStatus, 1);
        }
        return acc;
      }, new Map<TagStatus, number>());

    const allTagsAssigned =
      tagStatus.get("AssignedToUser") === updatedTags.length;
    const allTagsInStock = tagStatus.get("InStock") === updatedTags.length;
    setTagsToUpdate(updatedTags);
    if (allTagsInStock) {
      setShowBulkAssign(true);
    } else if (allTagsAssigned) {
      setShowBulkUnassign(true);
    } else {
      setShowBulkAssign(false);
      setShowBulkUnassign(false);
    }
  }, []);

  const onChangeTagSelection = useCallback(
    (tag: Tag, add: boolean) => {
      let updatedTags = tagsToUpdate.concat([]); // new array
      if (add) {
        updatedTags = tagsToUpdate.concat(tag);
      } else {
        const idx = tagsToUpdate.findIndex((it) => it.id === tag.id);
        if (idx !== -1) {
          updatedTags.splice(idx, 1);
        }
      }
      updateTableActions(updatedTags);
    },
    [tagsToUpdate, updateTableActions]
  );

  const clearSelected = useCallback(() => {
    setTagsToUpdate([]);
    updateTableActions([]);
  }, [updateTableActions]);

  const selectAll = useCallback(() => {
    updateTableActions(tagStore.tags.concat([]));
  }, [tagStore.tags, updateTableActions]);

  return (
    <Box>
      {tagStore.state === "pending" && <Spinner mb={4} />}
      {tagStore.error?.length > 0 && (
        <Alert status="error" mb={4}>
          <AlertIcon />
          {tagStore.error}
        </Alert>
      )}
      {/* Data grid filtering options */}
      <ListFilters
        fields={["id", "tagNumber", "createdAt", "status", "assignedToEmail"]}
        filters={filters}
        onFilterChange={(filters) => setFilters(filters)}
      />
      {/* Table actions */}
      <Flex direction="row">
        {tagsToUpdate.length > 0 ? (
          <Button m={2} onClick={() => clearSelected()}>
            {t("tags.tagList.deSelectTagsBtn")}
          </Button>
        ) : (
          <Button m={2} onClick={() => selectAll()}>
            {t("tags.tagList.selectAllTagsBtn")}
          </Button>
        )}
        <Box></Box>
        {showBulkAssign ? (
          <Box>
            <Button
              m={2}
              aria-label="Assign to user"
              colorScheme="black"
              onClick={() => assignToUser(tagsToUpdate)}>
              <SmallAddIcon /> {t("tags.tagList.assignToUser")}
            </Button>
          </Box>
        ) : (
          <></>
        )}
        {showBulkUnassign ? (
          <Box>
            <Button
              m={2}
              aria-label="Unassign from user"
              colorScheme="black"
              onClick={() => unAssignTags(tagsToUpdate)}>
              <SmallCloseIcon /> {t("tags.tagList.unassignFromUser")}
            </Button>
          </Box>
        ) : (
          <></>
        )}
      </Flex>

      {/* Form to select the user to assign to a tag */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("tags.tagList.assignTagTitle")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Select
              placeholder="Select option"
              onChange={(evt) => {
                if (evt.target.selectedIndex > 0) {
                  setSelectedUserId(evt?.target.value);
                }
              }}>
              {certifierStore.certifiers.map((certifier, idx) => (
                <option key={idx} value={certifier.id}>
                  {certifier.name} ({certifier.email})
                </option>
              ))}
            </Select>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => doAssignTag(selectedUserId)}>
              {t("tags.tagList.doAssignTag")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <ClassNames>
        {({ css }) => (
          <DataTable
            rowClassName={rowClassName}
            lazy={true}
            paginator={true}
            paginatorTemplate="PrevPageLink NextPageLink"
            totalRecords={totalRecords}
            rows={pageSize}
            value={tagStore.tags}
            first={offset}
            emptyMessage={t("tags.tagList.noResults")}
            loading={tagStore.state === "pending"}
            className={css`
              ${tableStyle}
            `}
            paginatorLeft={
              <ListPaginator
                defaultValue={pageSize}
                onPageSizeChange={(pageSize) => {
                  setPageSize(pageSize);
                  setPageNumber(0);
                }}
              />
            }
            paginatorRight={<Box />}
            onPage={(e: any) => setPageNumber(e.page)}>
            <Column
              style={{ width: "50px" }}
              body={(tag: Tag) => (
                <Checkbox
                  isChecked={
                    tagsToUpdate.findIndex((it) => it.id === tag.id) !== -1
                  }
                  onChange={(e) => {
                    onChangeTagSelection(tag, e.target.checked);
                  }}
                />
              )}
            />
            <Column field="tagNumber" header={t("tags.tagList.tagNumber")} />
            <Column field="createdAt" header={t("tags.tagList.createdAt")} />
            <Column field="status" header={t("tags.tagList.status")} />
            <Column
              header={t("tags.tagList.assignedTo")}
              body={(tag: Tag) =>
                tag.assignedToName ?? tag.assignedToEmail ?? tag.assignedTo
              }
            />
            <Column
              header={t("tags.tagList.tradeUnit")}
              body={(tag: Tag) =>
                tag.asset ? (
                  <ButtonGroup size="sm" spacing="2">
                    <Link href={`/trade-units/${tag.asset.id}`}>
                      <IconButton
                        aria-label="View"
                        colorScheme="black"
                        icon={<ViewIcon />}
                      />
                    </Link>
                  </ButtonGroup>
                ) : null
              }
            />
          </DataTable>
        )}
      </ClassNames>
    </Box>
  );
};

export default observer(TagList);
