import { Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { ContentArea } from "../app/shared";

const NotFoundPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ContentArea>
      <Text>{t("pages.notFoundPage.content")}</Text>
    </ContentArea>
  );
};

export default NotFoundPage;
