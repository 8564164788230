import React from "react";
import { ContentArea, Link } from "app/shared";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FishSpeciesEditor } from "app/fishery";

interface Params {
  id: string;
}

const FishSpciesPage: React.FC<RouteComponentProps<Params>> = ({ match }) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <ContentArea>
      <Breadcrumb mb={4} fontWeight="medium" fontSize="lg">
        <BreadcrumbItem>
          <BreadcrumbLink href="/fish-species" as={Link}>
            {t("pages.breadcrumbs.fishesSpecies")}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href={`/fish-species/${match.params.id}`} as={Link}>
            {t("pages.breadcrumbs.fishSpecies")} {match.params.id}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <FishSpeciesEditor
        id={match.params.id}
        onCreate={(fishSpciesId: string) =>
          history.push(`/fish-species/${fishSpciesId}`)
        }
        onDelete={() => history.push("/fish-species")}
      />
    </ContentArea>
  );
};

export default FishSpciesPage;
