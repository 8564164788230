import React, { ReactNode } from "react";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Stack,
} from "@chakra-ui/react";
import { FieldArray, FieldArrayRenderProps } from "formik";
import get from "lodash.get";
import { AddIcon } from "@chakra-ui/icons";

type FieldValue = string | number | Record<string, unknown> | any;

export interface ChildrenProps<T> {
  index: number;
  value: T;
  getFieldName: (field?: string) => string;
  setField: (field: string, value: T) => void;
  remove: () => void;
}

interface Props {
  name: string;
  label?: string;
  defaultArrayValue?: FieldValue;
  children: (
    values: ChildrenProps<FieldValue>[],
    arrayHelpers: FieldArrayRenderProps
  ) => ReactNode;
  onNewArrayItem?: (value: FieldValue, values: FieldValue[]) => void;
}

export const FormFieldArray: React.FC<Props> = ({
  name,
  label,
  children,
  defaultArrayValue,
  onNewArrayItem,
}) => (
  <FieldArray
    name={name}
    render={(arrayHelpers) => {
      const values = get(arrayHelpers.form.values, name);
      const errors = get(arrayHelpers.form.errors, name);

      return (
        <FormControl
          isInvalid={
            errors && get(arrayHelpers.form.touched, name) ? true : false
          }>
          <Stack isInline align="center">
            {label && <FormLabel>{label}</FormLabel>}
            {defaultArrayValue && (
              <IconButton
                aria-label="Add"
                size="xs"
                icon={<AddIcon />}
                onClick={() => {
                  arrayHelpers.push(defaultArrayValue);
                  onNewArrayItem?.(defaultArrayValue, values);
                }}
              />
            )}
          </Stack>
          {values?.length > 0 ? (
            children(
              values.map((value: FieldValue, index: number) => ({
                index,
                value,
                getFieldName: (field?: string) =>
                  `${name}.${index}${field ? `.${field}` : ""}`,
                setField: (field: string, value: FieldValue) =>
                  arrayHelpers.form.setFieldValue(
                    `${name}.${index}.${field}`,
                    value
                  ),
                remove: () => arrayHelpers.remove(index),
              })),
              arrayHelpers
            )
          ) : (
            <FormErrorMessage>{errors}</FormErrorMessage>
          )}
        </FormControl>
      );
    }}
  />
);
